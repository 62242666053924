<template>
  <b-card>
    <edit-field-header
      :title="title"
      @routerBack="$router.back()"
    />

    <edit-field
      :is-edit="true"
      :fields="fields"
      :row-data="row_data"
      post-api="/geofencing/AssignGeoFencing"
      finish-router="geoFencingAntennaEnvironment"
      permission="GeoFencing_Menu_Environment"
    >
    </edit-field>
  </b-card>
</template>

<script>
import fields from "./fields";
import EditField from "@/components/EditField2";
import EditFieldHeader from "@/components/EditFieldHeader";
import common from "@/common";

export default {
  name: "Edit",
  components: {
    EditFieldHeader,
    EditField,
    fields
  },
  data() {
    return {
      title: common.getMenuName('geoFencingAntennaEnvironment') + ' - ' + common.getI18n('common.edit'),
      fields,
      row_data: {
        deviceMAC: this.$route.params.mac,
        deviceName: this.$route.params.name,
        isUAT: this.$route.params.env,
      }
    }
  },
  mounted() {
    this.row_data.deviceMAC = common.decrypt(this.row_data.deviceMAC)
    this.row_data.deviceName = common.decrypt(this.row_data.deviceName)
    this.row_data.isUAT = common.decrypt(this.row_data.isUAT)
    if ([this.row_data.deviceMAC, this.row_data.isUAT].includes(undefined)) {
      this.$router.push({ path: '/' + this.$route.path.split('/')[1] })
      return false
    }
  }
}
</script>

<style scoped>

</style>
